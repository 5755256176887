import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { httpGetOne } from '../../fetch';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import Section from '../components/Section';
import ShopButton from '../components/ShopButton';
import Siteheader from '../components/Siteheader';

function Detail(props) {
    const [cart, setCart] = useState([]);
    const [product, setProduct] = useState({});

    useEffect(async ()=>{
        const localCart = localStorage.getItem('hersonCart');
        if(localCart){
            setCart(JSON.parse(localCart));
        }

        /* Getting the product */
        const href = window.location.href.split('/');
        const id = href[href.length - 1]
        const { data } = await httpGetOne('/products', id);
        console.log(data);
        setProduct(data.data);
    },[]);

    useEffect(()=>{
        const localCart = localStorage.getItem('hersonCart');
        if(localCart){
            localStorage.removeItem('hersonCart');
        }
        localStorage.setItem('hersonCart', JSON.stringify(cart));
    },[cart]);

    return (
        <div>
            <Navigation isSub={true} cart={cart} setCart={setCart}/>
            <br /><br />
            <Container className='details-wrapper'>
                <Row>
                    <Col xs={12} md={6}>
                        <img className='img-fluid' src={product.image} atl='mmm' />
                    </Col>
                    <Col xs={12} md={6}>
                        <div className='detail-section'>{product.section}</div>
                        <Row>
                            <Col><h1><b>{product.name}</b></h1></Col>
                        </Row>
                        <Row>
                            <Col className='price'>$ {product.price ? product.price.toFixed(2) : 0}</Col>
                        </Row>
                        <br />
                        <Row>
                            <ShopButton left='1rem' cart={cart} setCart={setCart} product={product}/>
                        </Row>
                        <br /><br />
                        <Row>
                            <Col>
                                <div className='detail-section'>Categoría: {product.section}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className='more-wrapper'>
                <div className='bar'></div>
                <Row>
                    <Col md={6} className='offset-md-3' >
                        <Row>
                            <Col style={{textAlign:'center'}}>INFORMACIÓN ADICIONAL</Col>
                        </Row>
                        <Row style={{borderBottom:'1px #8a8a8a solid', lineHeight: '2rem'}}>
                            <Col>Peso</Col>
                            <Col className='detail' style={{textAlign:'right'}}>1 {product.unit}</Col>
                        </Row>
                        <Row style={{borderBottom:'1px #8a8a8a solid', lineHeight: '2rem'}}>
                            <Col>Origen</Col>
                            <Col className='detail' style={{textAlign:'right'}}>{product.origin}</Col>
                        </Row>
                        <Row style={{borderBottom:'1px #8a8a8a solid', lineHeight: '2rem'}}>
                            <Col>Marca</Col>
                            <Col className='detail' style={{textAlign:'right'}}>{product.mark}</Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <br /><br />
            <Container>
                <Row>
                    <Col>
                        PRODUCTOS RELACIONADOS
                        <div className='bar100'></div>
                    </Col>
                </Row>
            </Container>
            <Section header={false} section={product.section} cart={cart} setCart={setCart} />
            <Footer></Footer>
        </div>
    );
}

export default Detail;