import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, FormText, FormFeedback } from 'reactstrap';
import NavigationStd from '../components/NavigationStd';
import Footer from '../components/Footer';
import { httpPost } from '../../fetch';

function Checkout(props) {

    const [cart, setCart] = useState([]);
    const [client, setClient] = useState({name: '', address: '', phone: '', email: ''});
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(async ()=>{
        const localCart = localStorage.getItem('hersonCart');
        if(localCart){
            setCart(JSON.parse(localCart));
        }
    },[]);

    useEffect(()=>{
        const localCart = localStorage.getItem('hersonCart');
        if(localCart){
            localStorage.removeItem('hersonCart');
        }
        localStorage.setItem('hersonCart', JSON.stringify(cart));
    },[cart]);

    const getTotal = (number=false) =>{
        let total = 0;
        cart.map(i => {
            total += i.product ? i.product.price * i.quantity : 0;
        })
        return number ? total : `$${total.toFixed(2)}`;
    }

    const submit = async() =>{
        if(client.name === '' || client.address === '' || client.phone === '' || client.email === ''){
            alert('Por favor, complete los datos antes de continuar');
            return;
        }
        if(client.phone.length !== 10) {
            alert('Por favor, revise el número de teléfono');
            return;
        }
        if(client.email.split('@').length && client.email.split('@').length < 2) {
            alert('Por favor, revise la dirección de correo');
            return;
        }
        
        toggle();
        const response = await httpPost('/orders/order/', { cart, client, totalAmount: getTotal(true) }, 'ANONIMO')
        setCart([])
        setClient({name: '', address: '', phone: '', email: ''})
        const msg = `Tu pedido ha sido creado. Folio: ${response.data}`;
        alert(msg)
    }

    
    return (
        <div>

            <div>
            <Modal isOpen={modal} toggle={toggle} className="modal-lg">
                <ModalHeader toggle={toggle}>Datos del pedido</ModalHeader>
                <ModalBody>
                    <p>Complete los datos a continuación para proceder con su pedido.</p>
                    <Form onSubmit={submit}>
                        <FormGroup row>
                            <Label for="input1" sm={5}>Nombre completo*</Label>
                            <Col sm={7}>
                                <Input type="text" name="input1" id="input1" 
                                    onChange={e => setClient({...client, name: e.target.value})} 
                                    value={client.name}>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="input2" sm={5}>Teléfono de contacto*</Label>
                            <Col sm={7}>
                                <Input type="number" name="input2" id="input2" 
                                    placeholder='Número a 10 dígitos'
                                    step='1'
                                    onChange={e => setClient({...client, phone: e.target.value})} 
                                    value={client.phone}>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="input3" sm={5}>Correo electrónico*</Label>
                            <Col sm={7}>
                                <Input type="text" name="input3" id="input3" 
                                    onChange={e => setClient({...client, email: e.target.value})} 
                                    value={client.email}>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="input4" sm={5}>Domicilio de envío*</Label>
                            <Col sm={7}>
                                <Input type="text" name="input4" id="input4" 
                                    onChange={e => setClient({...client, address: e.target.value})} 
                                    value={client.address}>
                                </Input>
                            </Col>
                        </FormGroup>
                        
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button style={{backgroundColor:'#B89117', border: 'none'}} onClick={submit}>Enviar</Button>{' '}
                    <Button color="danger" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
            </div>
            <NavigationStd />
            <br /><br />
            <Container style={{minHeight: '70vh'}}>
                <Row>
                    <Col md={6}><h1><b>Mi carrito</b></h1></Col>
                    <Col md={6} style={{textAlign:'right'}}>{cart.length} {cart.length > 1 ? 'Productos seleccionados' : 'Producto seleccionado'}</Col>
                </Row>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Unidad</th>
                                <th>Precio</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cart.map(i =>{
                                // console.log(i);
                                return (
                                    <tr id={i.product_id}>
                                        <td>{i.product.name}</td>
                                        <td>{i.quantity}</td>
                                        <td>{i.product.unit}</td>
                                        <td>${i.product.price.toFixed(2)}</td>
                                        <td><a href='#' onClick={()=>{setCart(cart.filter(x => x !== i))}} style={{color:'#868686'}}>Eliminar</a></td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td></td>
                                <td></td>
                                <td><b>TOTAL</b></td>
                                <td><b>{getTotal()}</b></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
                <Row>
                    <Col>
                        <Button style={{backgroundColor:'#B89117', border: 'none'}} disabled={cart.length === 0} onClick={toggle}>Proceder al Pago</Button>
                    </Col>
                    <Col style={{textAlign:'right'}}>
                        <Button color='danger' onClick={() => setCart([])} disabled={cart.length === 0}>Limpiar el carrito</Button>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

export default Checkout;