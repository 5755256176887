import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

function Admin(props) {
    return (
        <Container>
            <Row className='pt-2 pb-2' style={{backgroundColor: 'orange'}}>
                <Col><h2>Bienvenido, Administrador</h2></Col>
            </Row>
            <br />
            <Row>
                <Col md={12}>
                    <Button style={{backgroundColor: '#d56505'}} className='mr-2' href='#' onClick={()=> { window.location.href = '/admin/orders' }}>Pedidos</Button>
                    <Button style={{backgroundColor: '#d56505'}} className='mr-2' href='#'  onClick={()=> { window.location.href = '/admin/products' }}>Productos</Button>
                    <Button style={{backgroundColor: '#d56505'}} className='mr-2' href='#' onClick={()=> { window.location.href = '/admin/users' }}>Usuarios</Button>
                    {/* <Button color='primary' className='mr-2' href='#'>Inventario</Button> */}
                </Col>
            </Row>
        </Container>
    );
}

export default Admin;