import React from 'react';
import wa from '../../assets/ico01.png';
import fb from '../../assets/ico02.png';
import ig from '../../assets/ico03.png';
import { Col, Container, Row, Form, Input, Label } from 'reactstrap';

function Siteheader(props) {
    return (
        <div className='header-page'>
            <Container>
                <Row>
                    <Col sm={{offset: 8}} xs={{offset: 10}}>
                        <div className='icons-wrapper mb-2 mt-2'>
                            <a href="https://www.facebook.com/Hersonfoodservices" target="_blank"><img src={fb} className='icon' alt='fb' style={{ marginRight: '5px' }} /></a>
                            <a href="https://www.instagram.com/hersonfood/?hl=es-la" target="_blank"><img src={ig} className='icon' alt='ig' /></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Siteheader;