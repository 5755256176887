import React, { useEffect, useState } from 'react';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Section from './components/Section';
import Slider from './components/Slider';

const Main = (props) => {
    const [cart, setCart] = useState([]);

    useEffect(() => {
        const localCart = localStorage.getItem('hersonCart');
        if (localCart) {
            setCart(JSON.parse(localCart));
        }
    }, []);

    useEffect(() => {
        const localCart = localStorage.getItem('hersonCart');
        if (localCart) {
            localStorage.removeItem('hersonCart');
        }
        localStorage.setItem('hersonCart', JSON.stringify(cart));
    }, [cart]);

    return (
        <div>
            <Navigation isSub={true} cart={cart} setCart={setCart} cart={cart} />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Slider />
            <br />
            <br />
            <Section header={true} section='res' setCart={setCart} cart={cart} />
            <Section header={true} section='puerco' setCart={setCart} cart={cart} />
            <Section header={true} section='pollo' setCart={setCart} cart={cart} />
            <Section header={true} section='mariscos' setCart={setCart} cart={cart} />
            <Footer />
        </div>
    );
};

export default Main;