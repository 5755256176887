import React, { useState } from 'react';
import {
    Collapse,
    Nav, Navbar,
    NavbarBrand,
    NavbarText, NavbarToggler,
    NavItem,
    NavLink
} from 'reactstrap';
import logo from '../../assets/logo.png';
import Siteheader from './Siteheader';
import burger from '../../assets/burger.png';

const Navigation = ({isSub, cart, setCart}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
    <div>
        <Siteheader />
        <Navbar className='navbar' light expand="md">
            <NavbarBrand href="/"><img className='logo' src={logo} alt='herson'/></NavbarBrand>
            <NavbarToggler color='#ddd' onClick={toggle} ><img className='icon' src={burger} alt={burger} /></NavbarToggler>
            <Collapse isOpen={isOpen} navbar className='mr-5'>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink href='/'>Regresar</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    </div>
    );
}

export default Navigation;