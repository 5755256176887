import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { httpGet, httpPost } from '../../fetch';

const User = (props) => {
    const [user, setUser] = useState({type: 'Admin', username: '', password: '', displayname: ''});
    const [isEdit, setEdit] = useState(false);

    useEffect(async () => {
        const href = window.location.href.split('/');
        const id = href[href.length - 1]
        if (id !== 'newUser' && id !== 'editUser') {
            setEdit(true)
            const { data } = await httpGet('/users/' + id);
            setUser(data.data)
        }
    },[])

    const cancel =()=>{
        window.location.href = '/admin/users'
    }
    
    const save = async()=>{
        if(user.username.length < 8){
            alert('El nombre de usuario debe tener al menos 8 caracteres.')
            return
        }
        if(user.password.length < 8){
            alert('La contraseña debe tener al menos 8 caracteres.')
            return
        }
        try {
            const res = isEdit ? 
                await httpPost('/users/'+user._id, user, 'site')
                : await httpPost('/users', user, 'site');
            if(res && res.status === 200){
                alert('Usuario guardado');
                window.location.href = '/admin/users'
            }
        } catch (error) {
            alert('Ocurrió un error');
            window.location.href = '/admin/users'
        }
    }

    return (
        <Container>
            <br />
            <Row style={{ borderBottom: 'solid 1px #dee2e6', marginBottom: '1rem', paddingBottom: '1rem' }}>
                <Col xs={5} md={8}><h4>{isEdit ? 'Editar usuario' : 'Nuevo usuario'}</h4></Col>
                <Col xs={3} md={2}><Button onClick={e => cancel()}>Cancelar</Button></Col>
                <Col xs={3} md={2}><Button color='primary' onClick={e => save()}>Guardar</Button></Col>
            </Row>
            <Form>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='displayname' >Identificador</Label>
                            <Input id='displayname' name='displayname' value={user.displayname} onChange={e => setUser({ ...user, displayname: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor='email' >email</Label>
                            <Input id='email' name='email' value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='username' >Nombre de usuario</Label>
                            <Input id='username' name='username' value={user.username} onChange={e => setUser({ ...user, username: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='password' >Contraseña</Label>
                            <Input type='password' id='password' name='password' value={user.password} onChange={e => setUser({ ...user, password: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='type' >Tipo de usuario</Label>
                            <Input id='type' name='type' value={user.type} onChange={e => console.log('INVALID ACTION')} disabled/>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default User;