import React, { useEffect, useState } from 'react';
import wa from '../../assets/ico00.png';
import './Slider.css';

function Slider(props) {
    const [actual, setActual] = useState(false);

    useEffect(()=>{
        setTimeout(()=>setActual(!actual), 8000);
    },[actual])

    return (
        <div>
            {actual? 
            <div className='slide1'>
                <div className='slider-t1'>
                    LAS MEJORES CARNES
                </div>
                <div className='slider-t2'>Te lo llevamos a la puerta de tu casa</div>
                <div className='slider-t3'>Hermosillo, Sonora</div>
                <br />
                <div className='slider-t4'>Para envíos fuera de la ciudad comuníquese con nosotros <img className='icon' src={wa} alt='wa'/></div>
                {/* <img className='img-fluid' src={slide1} alt='slide1' /> */}
            </div>
            :
            <div className='slide2'>
                <div className='slider-t0'>
                    VARIEDAD DE MARISCOS
                </div>
                {/* <div className='slider-t0'>
                    RECIÉN SACADA DEL MAR
                </div> */}
                <div className='slider-t1'>
                    <a href="#mariscos" >COMPRAR</a>
                </div>
                {/* <div className='slider-t2'>Hasta su hogar</div> */}
                {/* <div className='slider-t3'>Hermosillo, Sonora</div> */}
                {/* <img className='img-fluid' src={slide2} alt='slide2' /> */}
            </div>
            }
        </div>
    );
}

export default Slider;