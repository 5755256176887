import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
//NAVIGATION ITEMS
import Login from './modules/Login';
import Main from './modules/Main';
import Checkout from './modules/pages/Checkout';
import Admin from './modules/pages/Admin';
import Detail from './modules/pages/Detail';
import Product from './modules/pages/Product';
import Products from './modules/pages/Products';
import User from './modules/pages/User';
import Users from './modules/pages/Users';
import Orders from './modules/pages/Orders';
import About from './modules/pages/About';
import PrivateRoute from './PrivateRoute';

function App() {

  return (
    <Router>
      <Route path="/" exact component={About} />
      <Route path="/detail/*" exact component={Detail} />
      <Route path="/store" exact component={Main} />
      <Route path="/checkout" exact component={Checkout} />
      <Route path="/login" exact component={Login} />
      <PrivateRoute exact path='/admin' component={Admin}></PrivateRoute>
      <PrivateRoute exact path='/admin/users' component={Users}></PrivateRoute>
      <PrivateRoute exact path='/admin/newUser' component={User}></PrivateRoute>
      <PrivateRoute exact path='/admin/editUser/*' component={User}></PrivateRoute>
      <PrivateRoute exact path='/admin/products' component={Products}></PrivateRoute>
      <PrivateRoute exact path='/admin/orders' component={Orders}></PrivateRoute>
      <PrivateRoute exact path='/admin/newProduct' component={Product}></PrivateRoute>
      <PrivateRoute exact path='/admin/editProduct/*' component={Product}></PrivateRoute>
    </Router>
  );
}

export default App;
