import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { httpDelete, httpGet, httpPut } from '../../fetch';
import AdminNavigation from '../components/AdminNavigation';

function Orders(props) {
    const [orders, setOrders] = useState([]);
    const [client, setClient] = useState({name: '', address: '', phone: '', email: ''});
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(()=>{
        fetchOrders();
    },[])

    const fetchOrders = async() =>{
        const { data } = await httpGet('/orders');
        setOrders(data.data);
    }
    const deleteOrders = async(id) =>{
        await httpDelete('/orders/', id, 'site');
        fetchOrders();
    }

    const submit = async () =>{
        console.log('ATENDIDO');
        await httpPut('/orders/order', client, 'site');
        fetchOrders();
        toggle();
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className="modal-lg">
                <ModalHeader toggle={toggle}>Datos del pedido</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup row>
                            <Label for="input1" sm={5}>Nombre completo</Label>
                            <Col sm={7}>
                                <Input type="text" name="input1" id="input1" 
                                    onChange={e => setClient({...client, name: e.target.value})}
                                    readOnly={true} 
                                    value={client.name}>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="input2" sm={5}>Teléfono de contacto</Label>
                            <Col sm={7}>
                                <Input type="number" name="input2" id="input2" 
                                    placeholder='Número a 10 dígitos'
                                    step='1'
                                    onChange={e => setClient({...client, phone: e.target.value})} 
                                    readOnly={true}
                                    value={client.phone}>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="input3" sm={5}>Correo electrónico</Label>
                            <Col sm={7}>
                                <Input type="text" name="input3" id="input3" 
                                    onChange={e => setClient({...client, email: e.target.value})} 
                                    readOnly={true}
                                    value={client.email}>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="input4" sm={5}>Domicilio de envío</Label>
                            <Col sm={7}>
                                <Input type="text" name="input4" id="input4" 
                                    onChange={e => setClient({...client, address: e.target.value})} 
                                    readOnly={true}
                                    value={client.address}>
                                </Input>
                            </Col>
                        </FormGroup>
                        <div>Pedido</div>
                        <div>
                        {
                            client.cart && client.cart.length > 0 ? 
                                client.cart.map(p =>{
                                    return (
                                        <div>
                                            <ul>
                                                <li>{p.product.name} [{p.product.code}]  - {p.quantity} {p.product.unit} - ${p.product.price}</li>
                                            </ul>
                                            
                                        </div>
                                    )
                                }): 'Error al procesar el carrito'
                        }
                            <h5 className="mt-4 text-center">TOTAL: ${client.totalAmount}</h5>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button style={{backgroundColor:'#B89117', border: 'none'}} onClick={submit} hidden={client.pending === false}>Atendido</Button>{' '}
                    <Button color="danger" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <AdminNavigation />

            <Container>
                <br />
                <Row>
                    <Col sm={10}><h4>Pedidos</h4></Col>
                    {/* <Col sm={2} style={{ textAlign: 'right' }}><Button onClick={e => { window.location.href = '/admin/newProduct' }}>+ Nuevo</Button></Col> */}
                </Row>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Estatus</th>
                            <th>Cliente</th>
                            <th>Teléfono</th>
                            <th>Correo</th>
                            <th>Monto Total</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orders && orders.length > 0 ? orders.map( o=> {
                                return (
                                <tr key={o._id}>
                                    <td style={o.pending === true ? {color: 'red'} : {color: 'green'}}>{o.pending === true ? 'Pendiente' : 'Entregado'}</td>
                                    <td>{o.name}</td>
                                    <td>{o.phone}</td>
                                    <td>{o.email}</td>
                                    <td>$ {o.totalAmount.toFixed(2)}</td>
                                    <td>
                                        <Button style={{marginTop:'0rem'}}
                                                onClick={() => {setClient(o); toggle()}}>Detalles</Button>
                                    </td>
                                    <td>
                                        <Button color='danger' style={{marginTop:'0rem'}}
                                                onClick={() => { if (window.confirm('¿Estás seguro que deseas eliminar este pedido?')) deleteOrders(o._id) }}>Eliminar</Button>
                                    </td>
                                </tr>
                                )
                            }) : <h4>Aun no hay pedidos</h4>
                        }
                    </tbody>
                </Table>
            </Container>
        </>
    );
}

export default Orders;