import React from 'react';
import { Container } from 'reactstrap';
import AdminNavigation from '../components/AdminNavigation';

function Admin(props) {
    return (
        <>
            <AdminNavigation />
            <br />
            <Container>
                <h1>Bievenido</h1>
            </Container>
        </>
    );
}

export default Admin;