import React, { useState } from 'react';
import './ShopButton.css';

function ShopButton({cart, setCart, product, ...props} ) {
    const [quantity, setQuantity] = useState(1);

    function addProduct(){
        setCart([...cart, {product, quantity}]);
        setQuantity(1);
    }

    return (
        <div className='shop-button' style={props.left? {marginLeft: props.left} : {}}>
            <button className='less-btn' onClick={() => setQuantity(quantity - 1)} disabled={quantity <= 1}>-</button>
            <input type='text' disabled className='inpute' value={quantity}></input>
            <button className='plus-btn' onClick={() => setQuantity(quantity + 1)} disabled={quantity > 9}>+</button>
            <button className='add-btn'onClick={() => addProduct()}>Añadir al carrito</button>
        </div>
    );
}

export default ShopButton;