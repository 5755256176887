import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { httpDelete, httpGet } from '../../fetch';
import AdminNavigation from '../components/AdminNavigation';

function Users(props) {
    const [users, setUsers] = useState([]);

    useEffect(()=>{
        fetchUsers();
    },[])

    const fetchUsers = async() =>{
        const { data } = await httpGet('/users');
        setUsers(data.data);
    }
    const deleteUser = async(id) =>{
        await httpDelete('/users', id, 'site');
        fetchUsers();
    }

    return (
        <>
            <AdminNavigation />
            <Container>
                <br />
                <Row>
                    <Col sm={10}><h4>Usuarios</h4></Col>
                    <Col sm={2} style={{ textAlign: 'right' }}><Button onClick={e => { window.location.href = '/admin/newUser' }}>+ Nuevo</Button></Col>
                </Row>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th className='d-none d-md-table-cell'>Nombre</th>
                            <th className='d-none d-md-table-cell'>Email</th>
                            <th>Tipo</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users && users.length > 0 ? users.map( u=> {
                                return (
                                <tr key={u._id}>
                                    <td>{u.username}</td>
                                    <td className='d-none d-md-table-cell'>{u.displayname}</td>
                                    <td className='d-none d-md-table-cell'>{u.email}</td>
                                    <td>{u.type}</td>
                                    <td>
                                        <Button style={{marginTop:'0rem'}}
                                                onClick={e => { window.location.href = '/admin/editUser/' + u._id }}>Editar</Button>
                                    </td>
                                    <td>
                                        <Button color='danger' style={{marginTop:'0rem'}}
                                                onClick={(e) => { if (window.confirm('¿Estás seguro que deseas eliminar este usuario?')) deleteUser(u._id) }}>Eliminar</Button>
                                    </td>
                                </tr>
                                )
                            }) : ''
                        }
                    </tbody>
                </Table>
            </Container>
        </>
    );
}

export default Users;