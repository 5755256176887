import axios from 'axios';

const backend = 'https://api.herson.sivira.net/api';
//const backend = 'http://localhost:6002/api';


export async function httpGet(path) {
    console.log('API CALL Get');
    const response = await axios.get(backend + path);
    return response;
}

export function httpGetOne(path, id) {
    console.log('API CALL GetOne' + path);
    return axios.get(backend + path + '/' + id);
}

export function httpPost(path, item, user) {
    console.log('API CALL Post' + path);
    return axios.post(backend + path, item);
}

export function httpPut(path, item, user) {
    console.log('API CALL Put' + path);
    return axios.put(backend + path, item);
}

export function httpDelete(path, id, user) {
    console.log('API CALL Delete' + path);
    return axios.delete(backend + path + '/' +id);
}

export function httpGetImg(filename){
    return backend+'/storage/'+filename;
}

export function httpPostw(path, item, config) {
    return axios.post(backend + path, item, config );
}