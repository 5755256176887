import React, { useEffect, useState } from 'react';
import { Row, Container, Col } from 'reactstrap';
import { httpGet } from '../../fetch';
import './Section.css';
import ShopButton from './ShopButton';

function Section({ section, cart, setCart, header }) {

    const [products, setProducts] = useState([]);

    useEffect( async ()=>{
        const { data } = await httpGet('/products/bySection?section='+section);
        setProducts(data.data)
    },[section]);
    
    return (
        <>
            {header === true? (
            <div className={`${section} header-section`} >
                <span style={{textTransform:'capitalize'}}>{section}</span>
            </div>
            ) :''}
            <Container id={section}>
                <Row>
                    {
                        products && products.length > 0 ? products.map(p => {
                            return(
                                <Col className='product-wrapper' xs={12} sm={2} md={3} id={p._id}>
                                    <a href={`/detail/${p._id}`}>
                                        <img width={200} src={p.image} alt={p.name} />
                                    </a>
                                    <a href={`/detail/${p._id}`}>
                                        <h5 className='product-name'>{p.name}</h5>
                                    </a>
                                    <p className='product-price'> ${p.price ? p.price.toFixed(2) : '-'}</p>
                                    <ShopButton setCart={setCart} cart={cart} product={p}></ShopButton>
                                </Col>
                            )
                        })
                        :''
                    }
                </Row>
            </Container>
        </>
    );
}

export default Section;