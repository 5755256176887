import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { httpGet, httpPost, httpPut, httpPostw, httpGetImg } from '../../fetch';

const Product = (props) => {
    const [product, setProduct] = useState({name: '', mark: '', origin: '', price: ''});
    const [isEdit, setEdit] = useState(false);
    const [file, setFile] = useState({file:null});

    useEffect(async () => {
        const href = window.location.href.split('/');
        const id = href[href.length - 1]
        if (id !== 'newProduct' && id !== 'editProduct') {
            setEdit(true)
            const { data } = await httpGet('/products/' + id);
            setProduct(data.data)
        }
    },[])

    const cancel =()=>{
        window.location.href = '/admin/products'
    }
    
    const save = async()=>{
        try {
            window.alert('No olvide, subir el archivo antes de Guardar')
            const res = isEdit ? 
                await httpPut('/products/'+product._id, product, 'site')
                : await httpPost('/products', product, 'site');
            if(res && res.status === 200){
                alert('Producto guardado');
                window.location.href = '/admin/products'
            }
        } catch (error) {
            alert('Ocurrió un error');
            window.location.href = '/admin/products'
        }
    }

    async function onFormSubmit(e){
        e.preventDefault();
        const formData = new FormData();
        formData.append('myImage', file.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        const data = await httpPostw("/uploads/upload",formData,config)
            .then((response) => {
                // console.log(response);
                const filename = response.data.data.filename;
                setProduct({...product, image: httpGetImg(filename)});
                alert("Archivo cargado correctamente, "+filename);
            }).catch((error) => {
                alert("Ocurrió un problema al subir imágen.");
        });
    }

    function onFileChange(e) {
        setFile({file:e.target.files[0]});
    }

    return (
        <Container>
            <br />
            <Row style={{ borderBottom: 'solid 1px #dee2e6', marginBottom: '1rem', paddingBottom: '1rem' }}>
                <Col xs={5} md={8}><h4>{isEdit ? 'Editar producto' : 'Nuevo producto'}</h4></Col>
                <Col xs={3} md={2}><Button onClick={e => cancel()}>Cancelar</Button></Col>
                <Col xs={3} md={2}><Button color='primary' onClick={e => save()}>Guardar</Button></Col>
            </Row>
            <div>
                <div >
                    <img style={{width:'100px'}} src={product.image}></img>
                </div>
                <form onSubmit={e=>onFormSubmit(e)}>
                    <h4>Imágen del producto</h4>
                    <input id="myImage" className="select-file" 
                            type="file" name="myImage" onChange={onFileChange}
                            accept=".png, .jpg, .jpeg" />
                    {/* <label className="light-goldeneye btn" htmlFor="myImage">Seleccionar Archivo</label> */}
                    <br className="movil-only" />
                    <br className="movil-only" />
                    <button className="btn btn-primary" type="submit">Subir Archivo</button>
                </form> 
            </div>
            <Form>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='name' >Nombre</Label>
                            <Input id='name' name='name' value={product.name} onChange={e => setProduct({ ...product, name: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='code' >Código</Label>
                            <Input type='code' id='code' name='code' value={product.code} onChange={e => setProduct({ ...product, code: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='section'>Sección</Label>
                            <Input type='select' value={product.section} onChange={e =>setProduct({...product, section: e.target.value})}>
                                <option value=''>Seleccionar sección...</option>
                                <option value='res'>Res</option>
                                <option value='puerco'>Puerco</option>
                                <option value='pollo'>Pollo</option>
                                <option value='mariscos'>Mariscos</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='mark' >Marca</Label>
                            <Input id='mark' name='mark' value={product.mark} onChange={e => setProduct({ ...product, mark: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='origin' >País de Origen</Label>
                            <Input id='origin' name='origin' value={product.origin} onChange={e => setProduct({ ...product, origin: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='price' >Precio al público</Label>
                            <Input type='price' id='price' name='price' value={product.price} onChange={e => setProduct({ ...product, price: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor='unit' >Unidad (KG)</Label>
                            <Input type='unit' id='unit' name='unit' value={product.unit} onChange={e => setProduct({ ...product, unit: e.target.value })} />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default Product;