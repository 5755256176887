import React, { useEffect, useState } from 'react';
import {
    Collapse,
    Container,
    Nav, Navbar,
    NavbarBrand,
    NavbarText, NavbarToggler,
    NavItem,
    NavLink
} from 'reactstrap';
import logo from '../../assets/logo.png';
import sh from '../../assets/ico04.png';
import bag from '../../assets/ico05.png';
import Siteheader from './Siteheader';
import burger from '../../assets/burger.png';
import Select from 'react-select';
import { httpGet } from '../../fetch';


const Navigation = ({ isSub, cart, setCart, notMain = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [options, setOptions] = useState([{ value: '', label: 'error loading...' }])
    const [value, setValue] = useState({});

    useEffect(async () => {
        const { data } = await httpGet('/products/search');
        setOptions(data.data.map(p => ({ label: p.name, value: p._id })));
    }, [])

    const setSearch = (selectedOpcion) => {
        setValue(selectedOpcion);
        console.log(selectedOpcion);
        window.location.href = `/detail/${selectedOpcion.value}`
    }

    const toggle = () => setIsOpen(!isOpen);

    const getTotal = () => {
        let total = 0;
        cart.map(i => {
            total += i.product ? i.product.price * i.quantity : 0;
        })
        return `$${total.toFixed(2)}`;
    }

    const getLen = () => {
        let total = 0;
        cart.map(i => {
            total += i.quantity;
        });
        return total;
    }

    return (
        <div style={{ position: 'fixed', zIndex: '999', width: '99vw' }}>
            <Siteheader />
            <Navbar className='navbar' light expand="md">
                <Container>
                    <NavbarBrand href="/"><img className='logo' src={logo} alt='herson' /></NavbarBrand>
                    <NavbarToggler color='#ddd' onClick={toggle} ><img className='icon' src={burger} alt={burger} /></NavbarToggler>
                    <Collapse isOpen={isOpen} navbar className='mr-5'>
                        <Nav className="ml-auto" navbar>
                            <NavItem style={{ display: 'inline-flex' }}>
                                <div style={{ display: 'inline-flex', marginTop: "11px" }}>
                                    <a href='/checkout'><img className='icon mr-3' src={bag} alt='social-media' style={{width: 15}} /></a>
                                    <a href='/checkout' style={{ color: 'white' }}><div className='bag-counter'>{getLen()}</div></a>
                                </div>
                                <NavLink href="/store">Food Store</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={`${isSub ? '/#nosotros' : '#nosotros'}`}>Nosotros</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={`${isSub ? '/#servicios' : '#servicios'}`}>Servicios</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={`${isSub ? '/#contacto' : '#contacto'}`}>Contacto</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                    <NavbarText className='ml-5' hidden={notMain}>
                        {
                            isSearch ?
                                <div style={{ display: 'inline-flex' }}>
                                    <Select
                                        className='search-input'
                                        value={value}
                                        onChange={setSearch}
                                        options={options}
                                        autoFocus={true}
                                    />
                                    <a href='#'><img className='icon mr-3' src={sh} alt='social-media' /></a>
                                </div>
                                :
                                <a href='#' onClick={() => setIsSearch(!isSearch)}><img className='icon mr-3' src={sh} alt='social-media' /></a>
                        }

                    </NavbarText>
                    <NavbarText hidden={notMain} >&nbsp;&nbsp;{getTotal()}</NavbarText>
                </Container>
            </Navbar>
        </div>
    );
}

export default Navigation;