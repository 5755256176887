import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { httpDelete, httpGet } from '../../fetch';
import AdminNavigation from '../components/AdminNavigation';

function Products(props) {
    const [products, setProducts] = useState([]);

    useEffect(()=>{
        fetchProducts();
    },[])

    const fetchProducts = async() =>{
        const { data } = await httpGet('/products');
        setProducts(data.data);
    }
    const deleteProducts = async(id) =>{
        await httpDelete('/products', id, 'site');
        fetchProducts();
    }

    return (
        <>
            <AdminNavigation />
            <Container>
                <br />
                <Row>
                    <Col sm={10}><h4>Productos</h4></Col>
                    <Col sm={2} style={{ textAlign: 'right' }}><Button onClick={e => { window.location.href = '/admin/newProduct' }}>+ Nuevo</Button></Col>
                </Row>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Sección</th>
                            <th className='d-none d-md-table-cell'>Marca</th>
                            <th className='d-none d-md-table-cell'>Origen</th>
                            <th>Precio</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            products && products.length > 0 ? products.map( p=> {
                                return (
                                <tr key={p._id}>
                                    <td>{p.name}</td>
                                    <td style={{textTransform: 'capitalize'}}>{p.section}</td>
                                    <td className='d-none d-md-table-cell'>{p.mark}</td>
                                    <td className='d-none d-md-table-cell'>{p.origin}</td>
                                    <td>{p.price}</td>
                                    <td>
                                        <Button style={{marginTop:'0rem'}}
                                                onClick={() => { window.location.href = '/admin/editProduct/' + p._id }}>Editar</Button>
                                    </td>
                                    <td>
                                        <Button color='danger' style={{marginTop:'0rem'}}
                                                onClick={() => { if (window.confirm('¿Estás seguro que deseas eliminar este producto?')) deleteProducts(p._id) }}>Eliminar</Button>
                                    </td>
                                </tr>
                                )
                            }) :''
                        }
                    </tbody>
                </Table>
            </Container>
        </>
    );
}

export default Products;