import React, { useState } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { httpPost } from '../fetch';

export default function Login(props) {
    const [loginData, setLoginData] = useState({
        username: '',
        password: ''
    })
    const [working, setWorking] = useState(false)
    // const [error, setError] = useState('')

    async function login(e) {
        console.log("try login ")
        e.preventDefault();
        try {
            setWorking(true)
            const response = await httpPost('/users/login', { ...loginData });
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', response.data.user);
            localStorage.setItem('userType', response.data.userType);
            localStorage.setItem('email', response.data.email);
            window.location.href = '/admin';
            
        } catch (e) {
            setWorking(false)
            // setError(e.response.data);
            alert('El usuario y/o la contraseña son incorrectos');
        }
    }

    return (
        <React.Fragment>
            <br />
            <Container>
                <Row>
                    <Col>
                        <b>Esta es una implementación de prueba, para acceder, utilice las siguientes credenciales:</b>
                        <p>
                            Usuario: admin
                        </p>
                        <p> 
                            Contraseña: adminadmin
                        </p>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col sm={12}>
                        <Form onSubmit={login}>
                            <FormGroup>
                                <Label for="username">Usuario:</Label>
                                <Input type="text" name="username" id="username" 
                                    value={loginData.username} 
                                    onChange={e => setLoginData({...loginData, username: e.target.value})} 
                                    placeholder="Nombre de usuario" required disabled={working}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Contraseña</Label>
                                <Input type="password" name="password" id="password" 
                                    value={loginData.password} 
                                    onChange={e => setLoginData({ ...loginData, password: e.target.value})} 
                                    placeholder="Contraseña" required disabled={working}/>
                            </FormGroup>
                            <FormGroup>
                                <Button type='submit' disabled={working}>Login</Button>
                            </FormGroup>
                            {working ? (
                                <h5>Conectando ... Por favor, espere ...</h5>
                            ) :''}
                        </Form>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}