import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Footer.css';

function Footer(props) {
    return (
        <div className='footer'>
            <Container>
                <Row>
                    <Col xs={12} sm={12}>
                        <b>HERSON© 2024</b> &nbsp;&nbsp;
                        | &nbsp;Todos los derechos reservados | &nbsp;Consulta aquí el aviso de privacidad.
                    </Col>

                    {/* <Col xs={4} sm={6} className='powered' style={{textAlign: 'right'}}>
                        <a href='http://www.desertmedia.com.mx' target='_blank'></a>
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
}

export default Footer;