import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Form, Input, Label } from 'reactstrap';
import herson1 from '../../assets/herson_1.jpg';
import herson2 from '../../assets/herson_2.jpg';
import herson3 from '../../assets/herson_3.jpg';
import cadena from '../../assets/cadena.png';
import clientes from '../../assets/clientes.png';
import h from '../../assets/h.png';
import s1 from '../../assets/s1.png';
import s2 from '../../assets/s2.png';
import s3 from '../../assets/s3.png';
import ig1 from '../../assets/ig1.png';
import ig2 from '../../assets/ig2.png';
import ig3 from '../../assets/ig3.png';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import Slider from '../components/Slider';
import Values from '../components/Values';

function About(props) {
    const [cart, setCart] = useState([]);

    useEffect(async () => {
        const localCart = localStorage.getItem('hersonCart');
        if (localCart) {
            setCart(JSON.parse(localCart));
        }
    }, []);

    useEffect(() => {
        const localCart = localStorage.getItem('hersonCart');
        if (localCart) {
            localStorage.removeItem('hersonCart');
        }
        localStorage.setItem('hersonCart', JSON.stringify(cart));
    }, [cart]);

    return (
        <div>
            <Navigation isSub={true} cart={cart} setCart={setCart} notMain={true} />
            <br /><br />
            <br /><br />
            <br />
            <Slider />

            <Container className='about-wrapper mt-5 mb-5'>
                <Row>
                    <Col>
                        <div className='about-section center'>Altamente calificados con</div>
                        <div className='about-section'><b>mas de 5 años de experiencia</b> en el mercado.</div>
                        <div className='about-line'></div>
                    </Col>
                </Row>
            </Container>

            <img id="nosotros" className='img-fluid' src={herson1} alt='herson1' />

            <Container className='about-wrapper mt-5 mb-5'>
                <Row>
                    <Col>
                        <div className='about-section center txt-black'>¿Quiénes somos?</div>
                        <div className='about-line'></div>
                    </Col>
                </Row>
            </Container>

            <Container className='about-wrapper mt-5 mb-5'>
                <Row>
                    <Col>
                        <p className='txt-gray text-​justify'> <b>Herson </b> es una empresa altamente calificada con 5 años de experiencia en el mercado
                            y tiene como objetivo el satisfacer las necesidades de nuestros clientes en el área de
                            servicio de alimentos, ya sea en el <b>abastecimiento de productos</b> para su comedor,
                            prestando el servicio de <b>comedor industrial</b> en su empresa y <b>comercialización de
                                productos</b> al por mayor.
                        </p>
                        <p className='txt-gray text-​justify'> La satisfacción de nuestros clientes siempre es lo más importante para nosotros,
                            enfocándonos siempre en brindar el <b>mejor servicio</b>, totalmente personalizado y de
                            excelente <b>calidad</b> a un <b>mejor costo</b>.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container className='about-wrapper mt-5 mb-5'>
                <Row>
                    <Col>
                        <div className='about-section center txt-black'>Valores</div>
                        <div className='about-line'></div>
                    </Col>
                </Row>
            </Container>

            <Values />

            <Container className='about-wrapper mt-5 mb-5'>
                <Row>
                    <Col xs={12} sm={6}>
                        <div className='about-section center txt-black'>Misión</div>
                        <p className="txt-gray text-​justify mt-3">
                            Satisfacer las necesidades de nuestros
                            clientes en el área de servicio de
                            alimentos, ya sea en el abastecimiento de
                            productos para su comedor, prestando el
                            servicio de comedor industrial en su
                            empresa y comercialización de
                            productos al por mayor
                        </p>
                    </Col>

                    <Col xs={12} sm={6}>
                        <div className='about-section center txt-black'>Visión</div>
                        <p className="txt-gray text-​justify mt-3">
                            Ser el principal proveedor de
                            suministros de alimentos para
                            empresas que manejan su propio
                            comedor industrial
                        </p>

                        <p className="txt-gray text-​justify mt-3">
                            Comercializar al por mayor productos
                            de alta calidad en la región.
                        </p>
                    </Col>
                </Row>
            </Container>

            <img className='img-fluid' src={herson2} alt='herson2' />

            <Container>
                <br />
                <br />
                <img className='img-fluid' src={cadena} alt='cadena' />
                <br />
                <br />
            </Container>

            <Container className='about-wrapper mt-5 mb-5'>
                <Row>
                    <Col xs={12} sm={6}>
                        <p className="txt-gray text-​justify mt-3">
                            Orgullosos de contar con el <b>DISTINTIVO H</b>, es un
                            reconocimiento que otorgan la Secretaría de
                            Turismo y la Secretaría de Salud, a aquellos
                            establecimientos fijos de alimentos y bebidas:
                            por cumplir con los estándares de higiene que
                            marca la Norma Mexicana <b>NMX-F605 NORMEX
                                2015</b>.
                        </p>
                    </Col>

                    <Col xs={12} sm={6}>
                        <div style={{ width: "50%", marginRight: "auto", marginLeft: "auto" }}>
                            <img className='img-fluid' src={h} alt='h' />
                        </div>
                    </Col>
                </Row>
            </Container>

            <img id="servicios" className='img-fluid' src={herson3} alt='herson3' />

            <Container className="mt-5">
                <Row>
                    <Col xs={12} sm={4}>
                        <div className="txt-gray mb-4" style={{ fontWeight: 700 }}>
                            Comercialización de productos al por mayor
                        </div>
                        <img className="img-fluid" src={s1} alt="servicios" />
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className="txt-gray mb-4" style={{ fontWeight: 700 }}>
                            Abastecimiento de productos para comedor industrial
                        </div>
                        <img className="img-fluid" src={s2} alt="servicios" />
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className="txt-gray mb-4" style={{ fontWeight: 700 }}>
                            Servicio de comedor industrial en empresas privadas
                        </div>
                        <img className="img-fluid" src={s3} alt="servicios" />
                    </Col>
                </Row>
            </Container>

            <Container className='about-wrapper mt-5 mb-5'>
                <Row>
                    <Col>
                        <div className='about-section center txt-black'>Clientes</div>
                        <div className='about-line'></div>
                    </Col>
                </Row>
            </Container>


            <div className="values-wrapper">
                <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
                    <Row>
                        <Col xs={12} sm={4}>
                            <br />
                            <div className='txt-gray' style={{ fontSize: "1.1rem" }}>
                                <p>
                                    <b>AGNICO SONORA SA DE CV</b>
                                    <br />
                                    Venta de productos para su comedor
                                </p>
                                <p>
                                    <b>SERVICIOS AGNICO EAGLE MEXICO SA DE CV</b>
                                    <br />
                                    Venta de productos para su comedor
                                </p>
                                <p>
                                    <b>JUAN ANTONIO CASTELO DE LA ROSA</b>
                                    <br />
                                    Servicio de comedor industrial
                                </p>
                                <p>
                                    <b>AGRÍCOLA LA FLORESTA SA DE CV</b>
                                    <br />
                                    Servicio de comedor industrial
                                </p>
                            </div>
                            <br />
                        </Col>
                        <Col xs={12} sm={4}>
                            <br />
                            <div className='txt-gray' style={{ fontSize: "1.1rem" }}>
                                <p>
                                    <b>ACUÍCOLA ALCATRAZ SA DE CV</b>
                                    <br />
                                    Venta de productos para su comedor
                                </p>
                                <p>
                                    <b>AQUACULTIVOS DE KINO SA DE CV</b>
                                    <br />
                                    Venta de productos para su comedor
                                </p>
                                <p>
                                    <b>AQUACTIVA SA DE CV</b>
                                    <br />
                                    Venta de productos para su comedor
                                </p>
                                <p>
                                    <b>FALCON PERFORACIONES MÉXICO SA DE CV</b>
                                    <br />
                                    Servicio de comedor industrial
                                </p>
                            </div>
                            <br />
                        </Col>
                        <Col xs={12} sm={4}>
                            <br />
                            <div className='txt-gray' style={{ fontSize: "1.1rem" }}>
                                <p>
                                    <b>INTEGRACIÓN EMPRESARIAL SIGLO XXI SA DE CV </b> <br />
                                    Servicio de comedor industrial
                                </p>
                                <p>
                                    <b>AGRÍCOLA BACATETE SA DE CV </b> <br />
                                    Venta de productos para su comedor
                                </p>
                                <p>
                                    <b>CASTESA SA DE CV </b> <br />
                                    Servicio de comedor industrial
                                </p>
                            </div>
                            <br />
                        </Col>
                    </Row>
                </div>
            </div>


            {/* <Container className='about-wrapper mt-5 mb-5'>
                <Row>
                    <Col>
                        <div className='about-section center txt-black'>Distribución de <br />productos al por mayor</div>
                        <div className='about-line'></div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <img className='img-fluid' src={clientes} alt='clientes' />
            </Container> */}

            <Container className='about-wrapper mt-5' style={{ border: "3px solid #f4f4f4" }}>
                <Row>
                    <Col className="txt-gray text-center">
                        <div className='about-section center txt-black'>Contáctanos</div>
                        <div className='about-line'></div>
                        <br />
                        <p>
                            Llene los datos del formulario y nos pondremos en contácto a la brevedad. Sus datos estarán protegidos.
                            <br />
                            Puede consultar nuestro Aviso de privacidad simplificado aquí.
                        </p>
                        <br />
                    </Col>
                </Row>
            </Container>

            <div style={{ backgroundColor: "#f4f4f4" }}>
                <Container className="txt-gray" style={{ fontSize: "1.1rem" }}>
                    <Form>
                        <br />
                        <Row>
                            <Col xs={12} sm={6}>
                                <Label for="nombre">Nombre</Label>
                                <Input type="text" name="nombre"></Input>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Label for="apellido">Apellido</Label>
                                <Input type="text" name="apellido"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <Label for="empresa">Empresa</Label>
                                <Input type="text" name="empresa"></Input>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Label for="email">Email</Label>
                                <Input type="text" name="email"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <Label for="telefono">Teléfono</Label>
                                <Input type="text" name="telefono"></Input>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Label for="asunto">Asunto</Label>
                                <Input type="text" name="asunto"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Label for="preguntas">Preguntas y/o comentarios</Label>
                                <Input type="textarea" name="preguntas"></Input>
                            </Col>
                        </Row>
                    </Form>
                </Container>
                <br />
            </div>

            <div id="mapwrapper" className="bg_map map-responsive">
                <a href="https://goo.gl/maps/8h5qtv5nWJ4Cempu5" target="_balnk">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3485.704825204007!2d-110.98272283504961!3d29.11439453452317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce8724d90f6677%3A0x5f79db69f099a783!2sHERSON%20Food%20%26%20Services!5e0!3m2!1ses!2smx!4v1625074733034!5m2!1ses!2smx"
                        width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy">
                    </iframe>
                </a>
            </div>

            <Container id="contacto" className='about-wrapper mt-5 mb-5'>
                <Row>
                    <Col>
                        <div className='txt-gray text-center'><b>Oficina</b></div>

                        <div className='txt-gray text-center'>
                            HERSON FOOD & SERVICES.
                        </div>
                        <div className='txt-gray text-center'>
                            Olivares #340 C33-36
                        </div>
                        <div className='txt-gray text-center'>
                            Colonia Luis Encinas, CP 83138
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col xs={12} sm={6}>
                        <div className='txt-gray text-center'>
                            <b>Correo:</b> gerencia@coherson.com
                        </div>
                    </Col>
                    <Col xs={12} sm={6}>
                        <div className='txt-gray text-center'>
                            <b>Telefono: </b> 6622-123456
                        </div>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col xs={12} sm={12} className="text-center">
                        <a href='https://www.facebook.com/Hersonfoodservices' target="_balnk">
                            <img className='icon mr-3' src={ig1} alt='social-media' />
                        </a>
                        <a href='https://www.instagram.com/hersonfood/?hl=es-la' target="_balnk">
                            <img className='icon mr-3' src={ig2} alt='social-media' />
                        </a>
                        <a href='https://api.whatsapp.com/send?phone=526621412361&text=Buen%20d%C3%ADa.%20Me%20gustar%C3%ADa%20pedir%20informaci%C3%B3n.' target="_balnk">
                            <img className='icon mr-3' src={ig3} alt='social-media' />
                        </a>
                    </Col>
                </Row>
            </Container>

            <Footer></Footer>
        </div>
    );
}

export default About;