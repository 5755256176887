import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import valores1 from '../../assets/valores1.png';
import valores2 from '../../assets/valores2.png';
import valores3 from '../../assets/valores3.png';
import valores4 from '../../assets/valores4.png';
import './Values.css';

function Values(props) {
    return (
        <div className="values-wrapper">
            <Container >
                <Row>
                    <Col xs={6} sm={3}>
                        <div style={{width: '50%', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img className='img-fluid' src={valores1} alt='valores1' />
                        </div>
                        <div className='txt-gray text-center'>
                            Honestidad en la información proporcionada por la empresa.
                        </div>
                    </Col>
                    <Col xs={6} sm={3}>
                        <div style={{width: '50%', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img className='img-fluid' src={valores2} alt='valores2' />
                        </div>
                        <div className='txt-gray text-center'>
                            Responsabilidad en todo servicio prestado.
                        </div>
                    </Col>
                    <Col xs={6} sm={3}>
                        <div style={{width: '50%', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img className='img-fluid' src={valores3} alt='valores3' />
                        </div>
                        <div className='txt-gray text-center'>
                            Integridad de nuestro personal y los representados
                        </div>
                    </Col>
                    <Col xs={6} sm={3}>
                        <div style={{width: '50%', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img className='img-fluid' src={valores4} alt='valores4' />
                        </div>
                        <div className='txt-gray text-center'>
                            Pasión siempre en todos los servicios que prestamos
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Values;